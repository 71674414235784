
class Footer extends HTMLElement {

  get currentCountryNode() {
    return this.querySelector('[data-node="countrySelection"]')
  }

  get countryTriggerNode() {
    return this.querySelector('[data-trigger="countrySelector"]')
  }

  get toggleTriggerNodes() {
    return this.querySelectorAll('[data-trigger="toggle"]')
  }

  renderCurrentCountry() {
    var countryCodeRegex = /^\/(\w{2})/;
    if (countryCodeRegex.test(window.location.pathname)){
      var countryCode = countryCodeRegex.exec(window.location.pathname)[1];
      let optionNode = this.countryTriggerNode.querySelector(`[value^="/${countryCode}"]`)
      optionNode.setAttribute('selected', '')
    }
  }

  switchCountry(countryURL) {
    window.location.pathname = countryURL
  }

  countryHandler(event) {
    let countryURL = event.target.value
    this.switchCountry(countryURL)
  }

  toggleGroup(node) {
    this.toggleTriggerNodes.forEach(trigger => {
      (trigger == node) ? node.parentNode.classList.toggle('open') : trigger.parentNode.classList.remove("open")
    })
  }

  toggleHandler(event) {
    if (event.target.getAttribute('data-trigger') == 'toggle') {
      this.toggleGroup(event.target)
    }
  }

  hideLinksFooter() {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('nav');
    if (param && param.includes('hidden')) {
      this.classList.toggle('-nav-hidden');
    }
  }

  connectedCallback() {
    this.hideLinksFooter()
    this.bind()
    this.renderCurrentCountry()
  }

  bind() {
    this.toggleTriggerNodes.forEach(trigger => trigger.addEventListener('click', e => { this.toggleHandler(e) }))
    this.countryTriggerNode.addEventListener('change', e => { this.countryHandler(e) });
  }
}

customElements.define('m-footer', Footer);
