import '../modules/footer.js'
import '../modules/accordion.js'
import '../modules/blockquote.js'
import '../cookies.js'
import '../attribution.js'
import '../blanktargets.js'

/*
let link = document.getElementById('-menu-mobile');
let burger = document.getElementById('burger');
let ul = document.querySelector('nav');

// Función para manejar el toggle de la clase '-open'
function toggleOpen() {
    burger.classList.toggle('-open');
    ul.classList.toggle('-open');
}

// Evento para el enlace principal
link.addEventListener('click', function(e) {
    e.preventDefault();
    toggleOpen();
});

// Evento para los enlaces dentro del ul
ul.addEventListener('click', function(e) {
    let target = e.target;
    if (target.tagName.toLowerCase() === 'a') {
        toggleOpen();
    }
});
*/

class Header extends HTMLElement {
    constructor(config = {}) {
      super();
      this.config = {
        desktopContainer: config.desktopContainer || '[data-origin="desktopContainer"]',
        mobileContainer: config.mobileContainer || '[data-destiny="mobileContainer"]',
        menuContent: config.menuContent || '[data-item="menu"]',
        openClass: config.openClass || "open",
        // ... otros selectores o configuraciones
      };
  
      this.lastClickedElement = null; // Inicializa lastClickedElement
  
      /* [item, origin, destiny] */
  
      this.items = [
        [
          this.querySelector(this.config.menuContent),
          this.querySelector(this.config.desktopContainer),
          this.querySelector(this.config.mobileContainer),
        ],
      ];
  
      /* [trigger, pointer] */
  
      this.triggersMobile = [
        [
          this.querySelector('[data-trigger="toggleMenu"]'),
          this.querySelector(this.config.mobileContainer),
        ],
      ];
      
      // Asegurarse de que los elementos existen antes de intentar acceder a ellos
      const menu = this.querySelector(this.config.menuContent);
  
      if (menu) {
        // Inicializar referencias a contenedores originales
        this.menuContent = menu.parentNode;
      }
    }
  
    get menuButtom() {
      return this.querySelector('a[data-trigger="toggleMenu"]');
    }

    get mHeader() {
      return this.querySelector('.m-header');
    }
  
    putMobile() {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i][0];
        let items = item.querySelectorAll('li');
        items.forEach(function(item) {
            item.addEventListener('click', function() {
                this.parentNode.parentNode.parentNode.parentNode.classList.toggle("open");
            });
        });
        const destiny =
          window.innerWidth < 1024 ? this.items[i][2] : this.items[i][1];
        destiny.appendChild(item);
      }
    }
  
    putToggleMobile(e) {
      if (window.innerWidth < 1024) {
        const foundSubArray = this.triggersMobile.find(
          (subArray) => subArray[0] === e.target
        );
        const isOpen = this.classList.contains("open");
        const isSameElement = this.lastClickedElement === e.target;
  
        if (foundSubArray) {
          if (!isOpen || isSameElement) {
            this.classList.toggle("open");
            foundSubArray[1].classList.toggle("isOpen");
          } else {
            // Eliminar 'isOpen' de todos los elementos
            this.triggersMobile.forEach((subArray) => {
              subArray[1].classList.remove("isOpen");
            });
            foundSubArray[1].classList.toggle("isOpen");
            this.classList.toggle("open");
          }
        }
        this.lastClickedElement = e.target;
      }
    }
  
    connectedCallback() {
      this.bind();
      this.putMobile();
      window.addEventListener("resize", () => {
        this.putMobile();
      });
    }
  
    bind() {
      this.menuButtom.addEventListener("click", (e) => this.putToggleMobile(e));
    }
  }
  
  customElements.define("m-header", Header);
  